<template>
  <section v-loading="loading" class="withDrawal-application-detail">
    <section class="status-wrapper">
      <div>
        <span class="status-text">{{ data.stateName }}</span>
        <span v-if="data.state == 6" style="color: #d9001b; padding-left: 50px">{{
          data.failReason
        }}</span>
      </div>
      <div class="status-ctrl">
        <el-button
          v-if="
            data.state === 1 && powerList.find((e) => e.nodeCode == 'YY_PAYCENTER_AGREEWITHDRAW')
          "
          icon="el-icon-check"
          type="primary"
          size="small"
          class="btn"
          @click="pass(1)"
          >通过</el-button
        >
        <el-button
          v-if="
            data.state === 1 && powerList.find((e) => e.nodeCode == 'YY_PAYCENTER_REFUSEWITHDRAW')
          "
          icon="el-icon-close"
          size="small"
          class="btn"
          @click="pass(2)"
          >驳回</el-button
        >
      </div>
    </section>
    <div class="content">
      <section class="with-draw-table-wrapper">
        <common-title title="申请信息"></common-title>
        <el-descriptions direction="vertical" :column="4" border>
          <el-descriptions-item label="提现编号">{{ data.withdrawNo }}</el-descriptions-item>
          <el-descriptions-item label="供应商ID">{{ data.storeId }}</el-descriptions-item>
          <el-descriptions-item label="供应商名称">{{ data.storeName }}</el-descriptions-item>
          <el-descriptions-item label="申请金额">
            <span class="moneyColor">¥{{ data.amount }}</span></el-descriptions-item
          >
          <el-descriptions-item label="申请时间">{{ data.applyTime }}</el-descriptions-item>
          <el-descriptions-item label=""></el-descriptions-item>
          <el-descriptions-item label=""></el-descriptions-item>
          <el-descriptions-item label=""></el-descriptions-item>
        </el-descriptions>
      </section>
      <section v-if="data.state != 1" class="with-draw-table-wrapper">
        <common-title title="打款账户信息"></common-title>
        <el-descriptions direction="vertical" :column="4" border>
          <el-descriptions-item label="收款银行">{{ data.bankName }}</el-descriptions-item>
          <el-descriptions-item label="收款银行卡号">{{ data.cardNumber }}</el-descriptions-item>
          <el-descriptions-item label="收款人">{{ data.cardName }}</el-descriptions-item>
          <el-descriptions-item label=""></el-descriptions-item>
        </el-descriptions>
      </section>
      <section v-if="data.state != 1" class="with-draw-table-wrapper">
        <common-title title="审核信息"></common-title>
        <el-descriptions direction="vertical" :column="4" border>
          <el-descriptions-item label="审核人">{{ data.auditUserName }}</el-descriptions-item>
          <el-descriptions-item label="审核时间">{{ data.auditTime }}</el-descriptions-item>
          <el-descriptions-item v-if="data.state === 4" label="驳回原因">{{
            data.remark
          }}</el-descriptions-item>
          <el-descriptions-item v-if="data.state === 2 || data.state === 3" label="备注">
            {{ data.remark }}</el-descriptions-item
          >
          <el-descriptions-item v-if="data.state === 2 || data.state === 3" label="打款金额">
            <span class="moneyColor">¥{{ data.amount }}</span>
          </el-descriptions-item>
          <el-descriptions-item v-if="data.state === 2 || data.state === 3" label="开票凭证">
            <div>
              <el-image
                v-for="(img, i) in data.billUrls ? data.billUrls : []"
                :key="i"
                :src="img"
                style="width: 100px; height: 100px"
                :preview-src-list="data.billUrls"
              >
              </el-image>
            </div>
          </el-descriptions-item>
          <el-descriptions-item
            v-if="data.state === 2 || data.state === 3"
            label=""
          ></el-descriptions-item>
          <el-descriptions-item v-if="data.state !== 4" label=""></el-descriptions-item>
          <el-descriptions-item label=""></el-descriptions-item>
        </el-descriptions>
      </section>

      <section v-if="data.state == 3" class="with-draw-table-wrapper">
        <common-title title="打款信息"> </common-title>
        <el-descriptions direction="vertical" :column="4" border>
          <el-descriptions-item label="打款时间">{{ data.payerTime }}</el-descriptions-item>
          <el-descriptions-item label=""></el-descriptions-item>
          <el-descriptions-item label=""></el-descriptions-item>
          <el-descriptions-item label=""></el-descriptions-item>
        </el-descriptions>
        <!-- <table>
          <thead>
            <tr>
              <th>确认打款人</th>
              <th>确认打款时间</th>
              <th>实际打款时间</th>
              <th>打款凭证</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ data.payerUserName }}</td>
              <td>{{ data.confimPayTime }}</td>
              <td>{{ data.payerTime }}</td>
              <td>
                <el-image
                  v-for="(img, i) in data.picUrls"
                  :key="i"
                  class="img"
                  style="width: 100px; height: 100px"
                  :src="img"
                >
                </el-image>
              </td>
            </tr>
          </tbody>
        </table> -->
      </section>
      <section class="with-draw-table-wrapper">
        <common-title title="分账记录"></common-title>
        <el-table v-loading="tableLoading" :data="tableData" border style="width: 100%">
          <el-table-column prop="splitNo" label="分账编号" min-width="220" align="center">
          </el-table-column>
          <el-table-column prop="serviceNo" label="服务订单ID" min-width="210" align="center">
          </el-table-column>
          <el-table-column prop="orderNo" label="加购订单ID" min-width="200" align="center">
          </el-table-column>
          <el-table-column prop="productName" label="单品名称" min-width="150" align="center">
          </el-table-column>
          <el-table-column prop="splitAmount" label="分账金额" min-width="120" align="center">
            <template slot-scope="scope">
              <span style="color: #f6a764">¥{{ scope.row.splitAmount }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="serviceCompleteTime"
            label="订单完成时间"
            min-width="160"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="splitTime" label="分账时间" min-width="160" align="center">
          </el-table-column>
        </el-table>
        <el-pagination
          :current-page.sync="form.pageNumber"
          background
          layout="total,prev, pager, next"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </section>
      <section class="footer">
        <el-button size="small" type="primary" @click="handleBack">返回</el-button>
      </section>
    </div>

    <WithdrawalModals
      :status="status"
      :visible="dialogFormVisible"
      :title="title"
      :row="row"
      @cancel="dialogFormVisible = false"
      @getTable="getDetail"
    />
  </section>
</template>

<script>
import WithdrawalModals from '@/components/withdrawalModals/index.vue';

import CommonTitle from '@/components/common-title/index.vue';
import { withdrawDetail, getWithdrawOrderList } from '@/api/pay';

export default {
  name: 'WithDrawalApplicationDetail',
  components: {
    CommonTitle,
    WithdrawalModals
  },
  data() {
    return {
      data: {},
      tableLoading: false,
      loading: false,
      title: '',
      dialogFormVisible: false,
      status: 0,
      row: {},
      total: 0,
      tableData: [],
      form: {
        pageSize: 10,
        pageNumber: 1,
        data: {}
      }
    };
  },
  computed: {
    powerList: function () {
      return this.$store.getters.getPowerList;
    }
  },
  mounted() {
    this.getDetail();
    this.getWithdrawOrderList();
  },
  methods: {
    handleBack() {
      this.$router.go(-1);
    },
    getWithdrawOrderList() {
      this.form.data.withdrawId = this.$route.query.id;
      this.tableLoading = true;
      getWithdrawOrderList({ ...this.form })
        .then((res) => {
          this.tableLoading = false;
          this.tableData = res.content;
          this.total = Number(res.total);
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.getWithdrawOrderList();
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val;
      this.getWithdrawOrderList();
    },
    getDetail() {
      this.loading = true;
      this.dialogFormVisible = false;
      withdrawDetail({
        data: this.$route.query.id
      }).then((res) => {
        this.loading = false;
        this.data = res;
      });
    },
    // 通过
    pass(state) {
      switch (state) {
        case 1:
          this.title = '通过';
          break;
        case 2:
          this.title = '驳回';
          break;
        case 3:
          this.title = '确认打款';
          break;
        default:
          this.title = '';
          break;
      }
      this.dialogFormVisible = true;
      this.row = this.data;
      this.status = state;
    }
  }
};
</script>

<style lang="less" scoped>
@import './index.less';
::v-deep.title {
  font-weight: bold !important;
}
.el-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}
</style>
